<div class="container-fluid merchantLogin">
    <div class="row">
      <a
        class="welcome_logo mt-4 ms-3"
        href="#"
        routerLink="/welcome"
        title="Oblingo"
        ><img src="../../assets/images/logo2.png" alt=""
      /></a>
    </div>
    <div class="row loginOrder">
      <div
        class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 hidedata text-center"
      >
        <img class="imgleftlogo" src="../../assets/images/storeimg.png" />
      </div>
      <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 loginnumber">
        <div class="well">
          <div class="card-body">
            <form [formGroup]="ResetpasswordForm" (ngSubmit)="onSubmit()" class="my-form">
              <div class="d-flex flex-column">
                <input
                  type="text"
                  placeholder="Reset Code"
                  class="form-control my-2 p-4"
                  formControlName="resetcode"
                  [ngClass]="{ 'is-invalid': submitted && tempData.resetcode.errors }"
                />
                <div
                  *ngIf="submitted && tempData.resetcode.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="tempData.resetcode.errors.required">
                    Reset code is required
                  </div>
                </div>
              </div>
  
              <div class="d-flex position-relative flex-column">
                <input
                [type]="fieldTextType ? 'text' : 'password'"
                  placeholder="New Password"
                  class="form-control my-2 p-4"
                  formControlName="newPassword"
                  [ngClass]="{ 'is-invalid': submitted && tempData.newPassword.errors }"
                />
                <div class="input-group-append pass_visible">
                  <span class="input-group-text">
                    <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType }" (click)="toggleFieldTextType()"></i>
                  </span>
               </div>
                <div
                  *ngIf="submitted && tempData.newPassword.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="tempData.newPassword.errors.required">
                    New Password is required
                  </div>
                </div>
              </div>
  
              <div></div>
              <button type="submit" class="btn1">Reset Password</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  