
<div class="container-fluid editmerc">
    <div class="user_prof_details">
    
      <div class="payment_results">
       
        <div class="table-responsive">
          <table>
            <tr >  
              <th>Date</th>
              <th>Merchant</th>
              <th>Amount ($)</th>
              <th></th>
            </tr>
            <tr *ngFor="let d of Data">
              <td>{{converttodate(d?.date) | date:"MM/dd/yyyy"}}</td>
              <td>{{d?.merchant_id?.name}}</td>
              <td>{{convertToFloat(d?.purchse_value)}}</td>
              <td class="viewdetails" (click)="viewdetails(d?.merchant_id?.id, d?.date)" > View Details </td>
            </tr>
          </table>
        </div>
        <div class="bottombtn d-flex justify-content-end">
      <button class="btn2">
          <img src="../../../assets/images/download.png" />Download E-Statement</button>
      </div>
      </div>
  
     
    </div>
  </div>
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
  
