import { Component, OnInit } from '@angular/core';
import { ApicallerService } from 'src/app/services/apicaller.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-merchant-loading',
  templateUrl: './merchant-loading.component.html',
  styleUrls: ['./merchant-loading.component.css']
})
export class MerchantLoadingComponent implements OnInit {
  merchantInfo:any
  content: any
  content1: any
  constructor( public router: Router, private apiCaller: ApicallerService,) { }

  ngOnInit(): void {
    this.getMerchant();
  }
  logout(){
    this.router.navigate(['/welcome']);
    localStorage.clear();
  }
  allproducts(){
    this.router.navigate(['/MerchantComponent/allproducts']);
  }
  editprofile(){
    this.router.navigate(['/MerchantComponent/merchantprofile']);
  }
  changepassword(){
    this.router.navigate(['/MerchantComponent/changepassword']);
  }

  getMerchant() {
    this.apiCaller.getMerchant().subscribe((merchantInfo: any) => {
      if (merchantInfo?.status === 'SUCCESS' && merchantInfo?.data) {
        this.merchantInfo = merchantInfo?.data;
      }
    })
  }
}
