import { Injector, Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
    HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse,
    HttpProgressEvent, HttpResponse, HttpUserEvent, HttpEvent, HttpErrorResponse, HttpHeaders
} from '@angular/common/http';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
    constructor() {
    }

    // tslint:disable-next-line:max-line-length
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

        const httpHeaders = req.headers;

        const token = localStorage.getItem('token'); // get token or clientid from localStorage or sessionStorage

        if (token !== null) {
            const hdrs = httpHeaders.append('Authorization', 'Bearer ' + token);

            const clonedReq = req.clone({ headers: hdrs });

            return next.handle(clonedReq);

        } else {
            return next.handle(req);
        }
    }
}