<!-- <header>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <a class="logo" routerLink="/welcome" href="#" title="Oblingo"
          ><img src="../../assets/images/logo2.png" alt="Oblingo"
        /></a>
      </div>
      <div class="col d-flex align-self-center justify-content-end">
        <div class="btn-group">
          <button
            type="button"
            class="btn dropdown-toggle usr_profile"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src="../../assets/images/user_icon.svg" alt="" /> Mr.
            Jaiprakash
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item">Profile</a></li>
            <li><a class="dropdown-item">Change Password</a></li>
            <li><a class="dropdown-item">Logout</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header> -->
<div class="container-fluid editmerc">
  <div class="user_prof_details">
    <h4>Change Password</h4>
    <form
      [formGroup]="changepasswordForm"
      (ngSubmit)="onSubmit()"
      class="my-form"
    >
      <div class="col-12 d-flex justify-content-center align-items-center mb-2">
        <div class="col-3 d-flex flex-row justify-content-between Qlabel">
          <label>Current Password</label><span>:</span>
        </div>
        <div class="col-9">
          <div class="d-flex position-relative flex-column ms-5">
            <input
              [type]="fieldTextType ? 'text' : 'password'"
              placeholder="Password"
              class="form-control my-2 p-4"
              formControlName="currentpassword"
              [ngClass]="{ 'is-invalid': submittedcpwd && changepasswordData.currentpassword.errors }"
            />
            <div class="input-group-append pass_visible">
              <span class="input-group-text">
                <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType }" (click)="toggleFieldTextType()"></i>
              </span>
           </div>
            <div
              *ngIf="submittedcpwd && changepasswordData.currentpassword.errors"
              class="invalid-feedback"
            >
              <div *ngIf="changepasswordData.currentpassword.errors.required">
                Current Password is required
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center align-items-center mb-2">
        <div class="col-3 d-flex flex-row justify-content-between Qlabel">
          <label>New Password</label><span>:</span>
        </div>
        <div class="col-9">
          <div class="d-flex position-relative flex-column ms-5">
            <input
              [type]="fieldTextType1 ? 'text' : 'password'"
              placeholder="Password"
              class="form-control my-2 p-4"
              formControlName="password"
              [ngClass]="{
                  'is-invalid': submittedcpwd && changepasswordData.password.errors
                }"
            />
            <div class="input-group-append pass_visible">
              <span class="input-group-text">
                <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType1,'fa-eye': fieldTextType1 }" (click)="toggleFieldTextType1()"></i>
              </span>
           </div>
            <div
              *ngIf="submittedcpwd && changepasswordData.password.errors"
              class="invalid-feedback"
            >
              <div *ngIf="changepasswordData.password.errors.required">
                New Password is required
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center align-items-start mb-2">
        <div class="col-3 d-flex flex-row justify-content-between mt-3 Qlabel">
          <label>Confirm Password</label><span>:</span>
        </div>
        <div class="col-9">
          <div class="d-flex position-relative flex-column ms-5">
            <input
            [type]="fieldTextType1 ? 'text' : 'password'"
              placeholder="Confirm Password"
              class="form-control my-2 p-4"
              formControlName="newpassword"
              [ngClass]="{
                  'is-invalid': submittedcpwd && changepasswordData.newpassword.errors
                }"
            />
            <div class="input-group-append pass_visible">
              <span class="input-group-text">
                <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType1,'fa-eye': fieldTextType1 }" (click)="toggleFieldTextType1()"></i>
              </span>
           </div>
            <div
              *ngIf="submittedcpwd && changepasswordData.newpassword.errors"
              class="invalid-feedback"
            >
              <div *ngIf="changepasswordData.newpassword.errors.required">
                Confirm Password is required
              </div>
              <div
                *ngIf="changepasswordData.newpassword.errors.confirmedValidator"
              >
                Confirm password should same as New password
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-start align-items-start mt-4">
        <button type="submit" class="btn1 me-3">Save</button>
      </div>
    </form>
  </div>
</div>
