import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApicallerService } from '../services/apicaller.service';
import { LoaderServiceService } from '../services/loader-service.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  merchantloginForm: any = UntypedFormGroup;
  submitted = false;
  fieldTextType: boolean = false;
  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    public apicaller: ApicallerService,
    public loaderservice : LoaderServiceService,
  ) {}
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  ngOnInit(): void {
    this.merchantloginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required]],
    });
  }
  get tempData() {
    return this.merchantloginForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.merchantloginForm.invalid) {
      return;
    } else {
      this.merchantlogin();
    }
  }

  merchantlogin() {
    console.log(Response);
    this.apicaller
      .merchantLogin(this.merchantloginForm.value.password, this.merchantloginForm.value.email)
      .subscribe((res: any) => { 
        if (res.status === 'SUCCESS' && res?.data?.emailVerified === true) {
          localStorage.setItem("login", "merchant");
          localStorage.setItem("ROLE", "merchant");
          localStorage.setItem('minfo', JSON.stringify(res?.data));
          localStorage.setItem('token', res?.data?.token);
          this.loaderservice.successNotification("LogIn Success.");
          setTimeout(() => {
            this.router.navigate(['/MerchantComponent']);
          }, 2000)
        } 
        else if(res?.data?.emailVerified === false) {
          this.loaderservice.failNotification("Your profile has not been verified, Please check your email for verification");
        }
        else {
          this.loaderservice.failNotification(res.message);
        }
      });
  }

}
