import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApicallerService } from '../services/apicaller.service';
import { LoaderServiceService } from 'src/app/services/loader-service.service';
import { ConfirmedValidator } from '../customdatevalidations';
declare var $: any;

@Component({
  selector: 'app-usertable',
  templateUrl: './usertable.component.html',
  styleUrls: ['./usertable.component.css']
})
export class UsertableComponent implements OnInit {
  selectedstatus: string="0";
  userinfo : any = localStorage.getItem('userInfo');
  Data:any;
  filename: any;
  isReport: boolean = true;
  requestObj: { startdate?: string, enddate?: string } = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';
  @Input() opts: string = '';
  @Input() filterType: string = '';
  @Input() isCustom: boolean = false;
  constructor(public router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderServiceService,
    private apiCallerService: ApicallerService) { }

  ngOnInit(): void {
    this.loaderService.close()
    $('#myTable').dataTable().fnDestroy();
    setTimeout(() => {
      this.loaderService.close()
     },2000)
    this.requestObj['startdate'] = this.startDate;
    this.requestObj['enddate'] = this.endDate;
    if (this.userinfo) {
      this.userinfo = JSON.parse(this.userinfo);
    }
    this.getTransactions();
  }

  ngOnChanges(changes: SimpleChanges) {
    $('#myTable').dataTable().fnDestroy();
    Object.keys(changes).forEach((change:any) => {
      if (change === 'startDate') {
        this.requestObj['startdate'] = changes[change].currentValue;
      }
      if (change === 'endDate') {
        this.requestObj['enddate'] = changes[change].currentValue;
      }
      if (change === 'opts') {
        this.opts = changes[change].currentValue;
      }
    });
    console.log('=== trequestObj =====', this.requestObj)
    this.getTransactions();
  }
  download(){    

    if(this.Data.length!=0){   
      this.filename='Transaction Report' ; 
      this.downloadFile(this.Data, this.filename);
      }else{
        alert("No data available in table.");
      }
  }
  userprofile(){
    this.router.navigate(["/userprofile"]);
  }
  logout(){
    this.router.navigate(['/welcome']);
    localStorage.clear();
  }
  userreport(){
    this.router.navigate(['LayoutComponent/report']);
  }
 
  downloadFile(data:any, filename='data') { 
    
    let date =filename;        
    let csvData = this.ConvertToCSV(data, ['Transaction Date','Order Id','Product Name', 'Debit Amount($)', 'Quantity', 'Balance($)']);
    console.log(csvData);   
    let blob;
    if(date==''){
       blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
   
    }else{
      blob = new Blob(['\ufeff' +  date + '\r\n' + csvData], { type: 'text/csv;charset=utf-8;' });
   
    } 
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if(isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray:any, headerList:any) {
     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
     let str = '';       
     let row = 'S.No,';  
     for (let index in headerList) {
         row += headerList[index] + ',';
     }
     row = row.slice(0, -1);
      str += row + '\r\n';
     for (let i = 0; i < array.length; i++) {       
         let line = (i+1)+'';            
         for (let index in headerList) { 
            let head = headerList[index];
             line += ',' + array[i][head];              
         }
         str += line + '\r\n';
     }
     return str;
     
 }

 getTransactions() { debugger
  $('#myTable').dataTable().fnDestroy();
  console.log(this.filterType);
  this.loaderService.present()
  if(this.filterType === 'today') {
    if (this.requestObj) {
      this.apiCallerService.getUserTransactions(this.requestObj).subscribe((response: any) => {
        if (response?.status === 'SUCCESS') {
          
          this.Data = response?.data || [];
          var self = this;
          setTimeout(() => {
            setTimeout(() => {
              var table = $('#myTable').DataTable(
                {
                  "pageLength": 10,
                  "searching": false,
                  "lengthChange": false,
        
                  "bDestroy": true,
                 
                },
                1500
              );
            });
            this.loaderService.close()
          }, 1000)
        } else{
          var table= $("#myTable").DataTable({});
        }
      })
    }
  } else if(this.filterType === 'yesterday') {
    if (this.requestObj) {
      this.apiCallerService.getUserTransactions(this.requestObj).subscribe((response: any) => {
        if (response?.status === 'SUCCESS') {
          
          this.Data = response?.data || [];
          var self = this;
          setTimeout(() => {
            setTimeout(() => {
              var table = $('#myTable').DataTable(
                {
                  "pageLength": 10,
                  "searching": false,
                  "lengthChange": false,
        
                  "bDestroy": true,
                 
                },
                1500
              );
            });
            this.loaderService.close()
          }, 1000)
        } else{
          var table= $("#myTable").DataTable({});
        }
      })
    }
  } else {
    if (this.requestObj) {
      this.apiCallerService.getUserTransactions(this.requestObj).subscribe((response: any) => {
        if (response?.status === 'SUCCESS') {
          
          this.Data = response?.data || [];
          var self = this;
          setTimeout(() => {
            setTimeout(() => {
              var table = $('#myTable').DataTable(
                {
                  "pageLength": 10,
                  "searching": false,
                  "lengthChange": false,
        
                  "bDestroy": true,
                 
                },
                1500
              );
            });
            this.loaderService.close()
          }, 1000)
        } else{
          var table= $("#myTable").DataTable({});
        }
      })
    }
  }
  
 }
 convertToFloat(val: string): string {
  return parseFloat(val || '0').toFixed(2);
 }
 getBalance(purchase: string, credit: string) {
  return (parseFloat(purchase || '0') - parseFloat(credit || '0'));
 }
}
