import { Component, OnInit } from '@angular/core';
import { ApicallerService } from '../services/apicaller.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-usertype',
  templateUrl: './usertype.component.html',
  styleUrls: ['./usertype.component.css']
})
export class UsertypeComponent implements OnInit {

  constructor(public router: Router, private apicaller: ApicallerService) { }

  ngOnInit(): void {
    localStorage.clear();
  }

  merchantlogin(){
    this.router.navigate(["/Login"], { queryParams: { role: 'merchant' } });
    // this.apicaller.login('merchant');
  }

  userlogin(){
    this.router.navigate(["/UserLogin"], { queryParams: { role: 'user' } });
    // this.apicaller.login('user');
  }
}
