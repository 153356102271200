<div class="container-fluid editmerc hvh-100">
  <div class="row">
    <div class="d-flex flexclass">
      <div class="search_aside" style="border-right: 1px solid #D1D1D1;">
        <button  (click)="filterback()" type="submit"  class="filterback">
            Back
          </button>
      </div>
      <div class="paymentactivity">
        <div class="container-fluid editmerc">
          <div class="user_prof_details">
            <label class="reportlab">Payment Activity</label>
            <p>Enter a merchant name, Date range or both to search payments</p>
            <form [formGroup]="transactioForm" (ngSubmit)="onSubmit()" class="my-form">
                  <mat-form-field class="example-full-width searchinput" appearance="fill">
                    <input type="text"
                          placeholder="Enter Merchant Name"
                          matInput
                          formControlName="merchantId"
                          [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option?.email">
                        {{option?.email}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            <div class="col-12 mt-5 daterange">
              <label class="drlab">Date Range <span>(Optional)</span></label>
              <div class="calender">
                  <div class="datepicouter">
                    <mat-form-field color="accent" appearance="fill">
                      <mat-label>Statement Start Date</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker1"
                        [max]="maxDate"
                        formControlName="startdate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker1"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker1 color="primary"></mat-datepicker>
                    </mat-form-field>
        
                    <mat-form-field
                      color="accent"
                      style="margin-left: 2em"
                      appearance="fill"
                    >
                      <mat-label>Statement End Date</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker2"
                        (dateChange)="EndDateChange($event)"
                        [min]="mintoDate"
                        [max]="maxDate"
                        formControlName="enddate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker2"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker2 color="primary"></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div>            
                    <button type="submit" class="btnreport">Search</button>
                  </div>
              
              </div>
            </div>
          </form>
          </div>
          <ng-container *ngIf="isData">
            <app-paymenttable [startDate]="startDate" [endDate]="endDate" [merchantId]="merchantId"></app-paymenttable>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>


