<div class="container-fluid">
  <div class="row topcss">
    <div class="col-sm-2 logo">
      <img class="logo" src="../../assets/images/logo2.png" alt="" />
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-7 col-lg-7 col-xl-7 col-sm-12 col-xs-12 hidedata text-center"
    >
      <img class="imgleftlogo" src="../../assets/images/userbg.png" />
    </div>
  </div>
</div>
