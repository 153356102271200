<header>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <a class="logo" (click)="logout()" title="Oblingo"
            ><img src="../../assets/images/logo2.png" alt="Oblingo"
          /></a>
        </div>
        <div class="col d-flex align-self-center justify-content-end">
          <div class="btn-group">
            <button
              type="button"
              class="btn dropdown-toggle usr_profile"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="../../assets/images/user_icon.svg" alt="" />
              {{userInfo?.name}}
            </button>
            <ul class="dropdown-menu">
              <li>
                <a (click)="profile()" class="dropdown-item ditem"
                  >Profile</a
                >
              </li>
              <li>
                <a (click)="report()" class="dropdown-item ditem"
                  >Transaction Activity</a
                >
              </li>
              <li>
                <a (click)="paymentstatement()" class="dropdown-item ditem"
                  >Payment Activity</a
                >
              </li>
              <li>
                <a (click)="logout()" class="dropdown-item ditem"
                  >Logout</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="container-fluid">
    <div class="row">
      <router-outlet></router-outlet>
    </div>
  </div>
  