import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApicallerService } from '../services/apicaller.service';

@Component({
  selector: 'app-paymentdetails',
  templateUrl: './paymentdetails.component.html',
  styleUrls: ['./paymentdetails.component.css']
})
export class PaymentdetailsComponent implements OnInit {
  productDetails: Array<any> = [];
  constructor(public router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    let values: any = this.route.snapshot.queryParamMap.get('values') || [];
    if (values) {
      values = JSON.parse(values);
      this.productDetails = values;
    }
  }
  back(){
    this.router.navigate(['LayoutComponent/payment-activity']);
  }
  convertToFloat(val: string): string {
    return parseFloat(val || '0').toFixed(2);
   }

}
