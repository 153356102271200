<div class="container-fluid merchantLogin">
    <div class="row">
      <a
        class="welcome_logo mt-4 ms-3"
        href="#"
        routerLink="/welcome"
        title="Oblingo"
        ><img src="../../assets/images/logo2.png" alt=""
      /></a>
    </div>
    <div class="row loginOrder">
      <div
        class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 hidedata text-center"
      >
        <img class="imgleftlogo" src="../../assets/images/storeimg.png" />
      </div>
      <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 loginnumber">
        <div class="well">
          <div class="card-body">
            <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="my-form">
              <div class="d-flex flex-column">
                <input
                  type="text"
                  placeholder="Email Address"
                  class="form-control my-2 p-4"
                  formControlName="email"
                  [ngClass]="{ 'is-invalid': submitted && tempData.email.errors }"
                />
                <div
                  *ngIf="submitted && tempData.email.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="tempData.email.errors.required">
                    Email Address is required
                  </div>
                  <div *ngIf="tempData.email.errors.email">
                    Email Address must be a valid email address
                  </div>
                </div>
              </div>
              <div></div>
              <button type="submit" class="btn1">Continue</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  