
<div class="container-fluid editmerc hvh-100">
    <div class="row">
      <div class="d-flex flexclass">
        <div class="search_aside" style="border-right: 1px solid #D1D1D1;">
            <button  (click)="filtersearch()" type="submit"  class="filtersearch ">
                Search
              </button>
        </div>
        <div class="paymentactivity">
            <div class="user_prof_details">
                <label class="reportlab">Payment Activity</label>
                <div
                  class="col-12 d-flex"
                  style="margin-top: 2%"
                >
                  <button type="submit"  class="btn1 edit"  [ngClass]="{ bg_color: sortType === 'month'}" (click)="getDates('month');sortType='month'">
                    Month To Date
                  </button>
                  <button type="submit"  class="btn1 edit" [ngClass]="{ bg_color: sortType === 'year'}" (click)="getDates('year');sortType='year'">Year to Date</button>
          
                </div>
                <app-paymenttable [sortType] = "sortType" [startDate]="startDate" [endDate]="endDate"></app-paymenttable>
              </div>
        </div>
      </div>
    </div>
    
  </div>
  
  
