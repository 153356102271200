import { Component, OnInit, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { ApicallerService } from '../services/apicaller.service';
import { LoaderServiceService } from '../services/loader-service.service';
declare var window: any;


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileForm: any = UntypedFormGroup;
  submitted = false;
  paytoken : any;
  token: string = '';
  authtoken: any;
  profileId: any;
  cardDetails: any;
  userId: string = '';
  userData: any;
  constructor( public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public apicaller: ApicallerService,
    public loaderservice : LoaderServiceService
    ) {
      this.activatedRoute.queryParams.subscribe(params => {
        const token = params['payment_token'];
        this.paytoken = token;
        this.userId = params['user'];
        if (this.userId) {
          this.getUser();
        }
      });
      this.profileId = localStorage.getItem("id");

      this.authtoken = localStorage.getItem("authtoken");
     }

  ngOnInit(): void {
    this.profileForm = this.formBuilder.group({
      fullname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      streetaddress: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipcode: ['', [Validators.required]]
    });

  }

  get profData() {
    return this.profileForm.controls;
  }



  ngAfterViewInit() {
  
    window.CollectJS.configure({
      'paymentType': 'cc',
      'callback': (response: any) => {
        this.token = response?.token;
        this.cardDetails = response.card.number;
      }
    })
  
  
  
  // const node = document.createElement('script')
  // node.src='../../../../assets/html.js'
  // node.type='text/javascript'
  // node.charset='utf-8'
  // document.getElementsByTagName('head')[0].appendChild(node);

  // const node = document.createElement('script')
  // node.src='../../../../assets/html.js'
  // node.type='text/javascript'
  // node.charset='utf-8'
  // document.getElementsByTagName('head')[0].appendChild(node)
  // document.addEventListener('DOMContentLoaded', function () {
  //   console.log('shshhs');
  //   CollectJS.configure({
  //     'paymentType': 'cc',
  //     'callback': function (response) {
  //      console.log('hhghh',response)
  //     }
  //   });
  // });
           

}

  onPayment() {
    // const requestObj = this.profileForm.value;
    if (this.token || this.userId) {
      const data: any = {

        name: this.profileForm.value.fullname,
  
        email: this.profileForm.value.email,
  
        payment_token: this.token,
  
        address1: this.profileForm.value.streetaddress,
  
        city: this.profileForm.value.city,
  
        state: this.profileForm.value.state,
  
        zipcode: this.profileForm.value.zipcode,

        id: this.profileId,
      }
      if (this.cardDetails) {
        data['cardDetails'] = {
          cardNo: `************${this.cardDetails.slice(-4)}`
        }
      }
      this.apicaller.updateUser(data,this.profileId,this.authtoken).subscribe((response : any)=> {
        if (response.status === 'SUCCESS') {
          localStorage.setItem('userInfo', JSON.stringify(data));
          this.loaderservice.successNotification("Profile Details updated Successfully.");
            this.router.navigate(["/LayoutComponent/userprofile"]);
        } else {
          this.loaderservice.failNotification("Something went wrong. Please try again");
        }
      }) 
    } else {
      this.loaderservice.failNotification("Something went wrong with the card details");
    }


  }

  // updateProfile(formData:any, token:any){
  //   this.apicaller
  //     .profileupdate(formData, token)
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       if (res.status === 'SUCCESS') {
  //         alert('update Success');
  //       } else {
  //         alert('update Failed');
  //       }
  //     });
  // }

  onSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    } else {
    }
  }

  getUser() {
    this.apicaller.getUser({}, this.userId).subscribe((userRes: any) => {
      if (userRes?.status == 'SUCCESS') {
        this.userData = userRes?.data;
        this.profileForm.controls['email'].setValue(this.userData.email);
        this.profileForm.controls['fullname'].setValue(this.userData.name);
        this.profileForm.controls['streetaddress'].setValue(this.userData.address1);
        this.profileForm.controls['city'].setValue(this.userData.city);
        this.profileForm.controls['state'].setValue(this.userData.state);
        this.profileForm.controls['zipcode'].setValue(this.userData.zipcode);
      }
    })
  }


  // updateProfile(formData:any, token:any){
  //   this.apicaller
  //     .profileupdate(formData, token)
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       if (res.status === 'SUCCESS') {
  //         alert('update Success');
  //       } else {
  //         alert('update Failed');
  //       }
  //     });
  // }
}
