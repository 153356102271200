
<div class="container-fluid editmerc">
  <div class="user_prof_details">
    <label class="reportlab">Transaction Activity</label>
    <div
      class="col-12 d-flex"
      style="justify-content: space-between; margin-top: 2%"
    >
      <button type="submit" (click)="customDate=false;isCustom=false;navigateToTransactions('yesterday')" class="btn1 edit" [ngClass]="{ bg_color: filterType === 'yesterday'}">
        Yesterday
      </button>
      <button type="submit" (click)="customDate=false;isCustom=false;navigateToTransactions('today')" class="btn1 edit" [ngClass]="{ bg_color: filterType === 'today'}">Today</button>
      <button type="submit" (click)="filterType='custom';custom()" class="btn1 edit" [ngClass]="{ bg_color: filterType === 'custom'}">Custom</button>
    </div>

    <div class="col-12 mt-5 daterange" *ngIf="customDate">
      <label class="drlab">Date Range</label>
      <div class="calender">
        <form
          [formGroup]="transactioForm"
          (ngSubmit)="onSubmit()"
          class="my-form"
        >
          <div class="datepicouter">
            <mat-form-field color="accent" appearance="fill">
              <mat-label>Transaction Start Date</mat-label>
              <input
                matInput
                [matDatepicker]="picker1"
                [max]="maxDate"
                formControlName="startdate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1 color="primary"></mat-datepicker>
            </mat-form-field>

            <mat-form-field
              color="accent"
              style="margin-left: 2em"
              appearance="fill"
            >
              <mat-label>Transaction End Date</mat-label>
              <input
                matInput
                [matDatepicker]="picker2"
                (dateChange)="EndDateChange($event)"
                [min]="mintoDate"
                [max]="maxDate"
                formControlName="enddate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2 color="primary"></mat-datepicker>
            </mat-form-field>
          </div>
          <div>
           <button type="submit" class="btnreport">Get Transactions</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-usertable [startDate]="startDate" [endDate]="endDate" [opts]="opts" [filterType]="filterType" [isCustom]="isCustom"></app-usertable>
