import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logocontainer',
  templateUrl: './logocontainer.component.html',
  styleUrls: ['./logocontainer.component.css']
})
export class LogocontainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
