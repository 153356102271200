import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangepasswordComponent } from '../changepassword/changepassword.component';
import { EditprofileComponent } from '../editprofile/editprofile.component';
import { ProfileComponent } from '../profile/profile.component';
import { RouteauthenticatorGuard } from '../routeauthentication/routeauthenticator.guard';
import { UserprofileComponent } from '../userprofile/userprofile.component';
import { UserreportComponent } from '../userreport/userreport.component';
import { UsertableComponent } from '../usertable/usertable.component';
import { PaymentStatementComponent } from '../payment-statement/payment-statement.component';
import { PaymentactivityComponent } from '../paymentactivity/paymentactivity.component';
import { PaymenttableComponent } from '../paymenttable/paymenttable.component';
import { PaymentdetailsComponent } from '../paymentdetails/paymentdetails.component';
const routes: Routes = [
  {path : '', redirectTo : 'usertable', pathMatch:'full'},
  { path: 'usertable', component: UsertableComponent, canActivate: [RouteauthenticatorGuard],
  },
  { path: 'changepassword', component:ChangepasswordComponent,canActivate: [RouteauthenticatorGuard]},
  { path: 'report', component:UserreportComponent},
  { path: 'paymentstatements', component:PaymentStatementComponent},
  { path: 'userprofile', component:UserprofileComponent, canActivate: [RouteauthenticatorGuard],
 },
  { path: 'editprofile', component:EditprofileComponent,canActivate: [RouteauthenticatorGuard]},
  { path: 'payment-activity', component: PaymentactivityComponent, canActivate: [RouteauthenticatorGuard]},
  { path: 'paymentdetails', component: PaymentdetailsComponent, canActivate: [RouteauthenticatorGuard]},
  { path: 'paymenttable', component: PaymenttableComponent, canActivate: [RouteauthenticatorGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
