
<div class="container-fluid editmerc">
    <div class="user_prof_details">
      <label class="reportlab">Transaction Activity</label>
      <div class="payment_results">
       
        <div class="table-responsive">
          <table>
            <tr>  
              <th>Product Name</th>
              <th>Price</th>
              <th>Quantity</th>
              
            </tr>
            <tr *ngFor="let product of productDetails">
              <td>{{product?.name}}</td>
              <td>{{convertToFloat(product?.price)}}</td>
              <td>{{product?.quantify}}</td>
            </tr>
          </table>
        </div>
        <div class="bottombtn d-flex justify-content-between">
          <button (click)="back()"  class="btn2 back">Back</button>
          <button class="btn2">
          <img src="../../../assets/images/download.png" />Download E-Statement</button>
      </div>
  
     
    </div>
  </div>
  
  
