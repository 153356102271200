import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApicallerService } from '../../services/apicaller.service';
import { LoaderServiceService } from '../../services/loader-service.service';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  ResetpasswordForm: any = UntypedFormGroup;
  submitted = false;
  fieldTextType: boolean = false;
  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    public apicaller: ApicallerService,
    public loaderservice : LoaderServiceService
  ) {}

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  ngOnInit(): void {
    this.ResetpasswordForm = this.formBuilder.group({
      resetcode: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
    });
  }

  get tempData() {
    return this.ResetpasswordForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.ResetpasswordForm.invalid) {
      return;
    } else {
      this.ResetPassword();
    }
  }

  ResetPassword() {
    this.apicaller
      .merchantResetPassword(this.ResetpasswordForm.value.resetcode, this.ResetpasswordForm.value.newPassword)
      .subscribe((res: any) => { 
        if (res.status === 'SUCCESS') {
          this.loaderservice.successNotification("Password Reset Successfully.");
          setTimeout(() => {
            this.router.navigate(['/Login']);
          }, 2000)
        } else {
          this.loaderservice.failNotification(res.status);
        }
      });
  }

}
