<div class="container-fluid editmerc">
  <div class="user_prof_details">
    <h4>Profile Details</h4>
    <div class="col-12 d-flex justify-content-center align-items-center mb-3">
      <div class="col-3 d-flex flex-row justify-content-between Qlabel">
        <label>Name</label><span>:</span>
      </div>
      <div class="col-9 Dlabel"><label>{{userData?.name}}</label></div>
    </div>
    <div class="col-12 d-flex justify-content-center align-items-center mb-3">
      <div class="col-3 d-flex flex-row justify-content-between Qlabel">
        <label>Email Address</label><span>:</span>
      </div>
      <div class="col-9 Dlabel">
        <label>{{userData?.email}}</label>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-center align-items-center mb-3">
      <div class="col-3 d-flex flex-row justify-content-between Qlabel">
        <label>Phone Number</label><span>:</span>
      </div>
      <div class="col-9 Dlabel"><label>{{userData?.mobileNo}}</label></div>
    </div>
    <div class="col-12 d-flex justify-content-center align-items-center mb-3">
      <div class="col-3 d-flex flex-row justify-content-between Qlabel">
        <label>Address</label><span>:</span>
      </div>
      <div class="col-9 Dlabel">
        <label>{{userData?.address1}}, {{userData?.city}}, {{userData?.state}}, {{userData?.zipcode}}</label>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-center align-items-center mb-3">
      <div class="col-3 d-flex flex-row justify-content-between Qlabel">
        <label>Credit Card Number</label><span>:</span>
      </div>
      <div class="col-9 Dlabel"><label style="letter-spacing: 3px">{{userData?.cardDetails?.cardNo}}</label></div>
    </div>
    <div class="col-12 d-flex justify-content-start align-items-start mt-5">
      <button type="button" (click)="edit()" class="btn1 edit">
        Edit
      </button>
    </div>
  </div>
</div>
