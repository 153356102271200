import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoaderServiceService } from './loader-service.service';

@Injectable({
  providedIn: 'root',
})
export class ApicallerService {
  apiURL: any;
  isLogin = false;
  roleAs: any;
  merchantInfo: any;
  constructor(private http: HttpClient, public router: Router, private loaderService: LoaderServiceService) {
    this.apiURL = environment.hostUrl;
    this.merchantInfo = localStorage.getItem('minfo');
    if (this.merchantInfo) {
      this.merchantInfo = JSON.parse(this.merchantInfo);
    }
  }

  login(value: string) {
    this.isLogin = true;
    this.roleAs = value;
    localStorage.setItem('ROLE', this.roleAs);
    return of({ success: this.isLogin, role: this.roleAs });
  }

  logout() {
    this.isLogin = false;
    this.roleAs = '';
    localStorage.setItem('STATE', 'false');
    localStorage.setItem('ROLE', '');
    return of({ success: this.isLogin, role: '' });
  }

  isLoggedIn() {
    const loggedIn = localStorage.getItem('STATE');
    if (loggedIn == 'true')
      this.isLogin = true;
    else
      this.isLogin = false;
    return this.isLogin;
  }

  getRole() {
    this.roleAs = localStorage.getItem('ROLE');
    return this.roleAs;
  }

  getOtp(data: any) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(this.apiURL + 'client/auth/send_login_otp', {
        mobileNo: data,
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  verifyOtp(phno: any, otp: any) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(this.apiURL + 'client/auth/login_with_otp', {
        mobileNo: phno,
        code: otp,
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  merchantLogin(password: any, email: any) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(this.apiURL + 'merchant/auth/login', {
        password: password,
        email: email,
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }
  merchantResetPassword(code: any, newPassword: any) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .put(this.apiURL + 'merchant/auth/reset-password', {
        code: code,
        newPassword: newPassword
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }
  merchantForgotPassword(emailid: any) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(this.apiURL + 'merchant/auth/forgot-password', {
        email: emailid
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  registerMerchant(pwd: any, emailid: any) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(this.apiURL + 'merchant/auth/register', {
        password: pwd,
        email: emailid
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }


  updateUser(profileObj:any, profid:any, authtoken:any){
    return this.http
      .post(this.apiURL + 'client/auth/update/' + profid,profileObj)
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  profileupdate(data:any) {
    return this.http
      .post(this.apiURL + `client/auth/update/${data['id']}`, data)
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }
edituser(data: any, id: any){
  return this.http
      .post(this.apiURL + `client/auth/update/${id}`, data)
      .pipe(retry(1), catchError(this.handleError.bind(this)));
}

  addProduct(data:any) {
    // const httpOptions = this.setHeaders();
    data['mid'] = this.merchantInfo?.mid[0];
    return this.http
      .post(this.apiURL + 'merchant/product/create', data)
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getProducts() {
    // const httpOptions = this.setHeaders();
    return this.http.get(`${this.apiURL}merchant/getProducts`)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  deleteProduct(productId: string) {
    // const httpOptions = this.setHeaders();
    return this.http.delete(`${this.apiURL}merchant/product/delete/${productId}`)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getProductById(productId: string) {
    // const httpOptions = this.setHeaders();
    return this.http.get(`${this.apiURL}merchant/product/${productId}`)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  updateProduct(productId: string, obj: any) {
    // const httpOptions = this.setHeaders();
    return this.http.put(`${this.apiURL}merchant/product/update/${productId}`, obj)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getMerchant() {
    // const httpOptions = this.setHeaders();
    const id = this.getmerchantid();
    return this.http.post(`${this.apiURL}merchant/auth/get/${id}`, '')
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getMerchantSettings() {
    // const httpOptions = this.setHeaders();
    const id = this.getmerchantUserid();
    return this.http.get(`${this.apiURL}merchant/merchant/${id}`)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getcategory(){
    const id = this.getmerchantid();
    return this.http.post(`${this.apiURL}merchant/category/list`, {id, "options": {pagination: false}})
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  updateMerchant(obj: any) {
    // const httpOptions = this.setHeaders();
    const id = this.getmerchantid();
    return this.http.post(`${this.apiURL}merchant/auth/update/${id}`, obj)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  updateMerchantSettings(obj: any) {
    const id = this.getmerchantUserid();
    return this.http.put(`${this.apiURL}merchant/merchant/update/${id}`, obj)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getTransactions(requestObj: any = {}) {
    const id = this.getmerchantid();
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': ' Bearer ' + token });
    requestObj['merchantId'] = id;
    return this.http.post(`${this.apiURL}merchant/transaction/getTransactions`, requestObj)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getUserTransactions(requestObj: any = {}) {
    
    return this.http.post(`${this.apiURL}client/api/v1/transaction/gettransactions`, requestObj)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getPaymentTransactions(requestObj: any = {}) {
    return this.http.post(`${this.apiURL}client/api/v1/payment/getTransactions`, requestObj)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  changePassword(requestObj: any = {}) {
    return this.http.put(`${this.apiURL}merchant/user/change-password`, requestObj)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  uploadImage(formData: any) {
    return this.http.post(`${this.apiURL}admin/upload`, formData)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getUser(data: any, id:any) {

    return this.http

      .post(this.apiURL + `client/auth/get/${id}`, data)

      .pipe(retry(1), catchError(this.handleError.bind(this)));

  }

  verifyEmail(token: string) {
    // const httpOptions = this.setHeaders();
    return this.http.get(`${this.apiURL}merchant/auth/verifyEmail?token=${token}`)
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getMerchants() {
    // const httpOptions = this.setHeaders();
    return this.http.post(`${this.apiURL}client/api/v1/merchant/list`, {
      "options": {
        "page": 1,
        "limit": 1000,
        "pagination": false
      },
      "isCountOnly": false
    })
    .pipe(retry(1), catchError(this.handleError.bind(this)));
  }
  // setHeaders() {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': ' Bearer ' + this.token });
  //   return { headers };
  // }


  handleError(error: any) {
    let errorMessage = '';
    if (error.error) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    this.loaderService.failNotification(errorMessage);
    return throwError(() => new Error(errorMessage));
  }

  getmerchantid(){
    let obj: any = localStorage.getItem('minfo');
    if(obj){
    obj = JSON.parse(obj);
    return obj.id;
    }
  }

  getmerchantUserid(){
    let obj: any = localStorage.getItem('minfo');
    if(obj){
    obj = JSON.parse(obj);
    return obj.merchant.id;
    }
  }


}

