import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApicallerService } from '../services/apicaller.service'; 

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.css']
})
export class EditprofileComponent implements OnInit {
  editprofileForm: any = UntypedFormGroup;
  userinfo : any = localStorage.getItem('userInfo');
  submitted = false;
  constructor( public router: Router,
    private formBuilder: UntypedFormBuilder,
    public apicaller: ApicallerService) { }

  Validate(event: {
    charCode: number;
    which: number;
    preventDefault: () => void;
  }) {
    var regex = new RegExp('^[0-9-!+@#$%*?]');
    var key = String.fromCharCode(
      event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
  
  ngOnInit(): void {
    this.editprofileForm = this.formBuilder.group({
      fullname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phonenumber: ['', [Validators.required, Validators.maxLength]],
      streetaddress: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipcode: ['', [Validators.required]]
    }); 

    if (this.userinfo) {
      this.userinfo = JSON.parse(this.userinfo);
    }debugger
    let data = { id : this.userinfo.id }



    this.apicaller.getUser(data, this.userinfo.id).subscribe((updateRes: any) => {

      if (updateRes?.status === 'SUCCESS') {

        this.editprofileForm.patchValue({

          fullname: updateRes.data.name,

          email:  updateRes.data.email,

          phonenumber: updateRes.data.mobileNo,

          streetaddress: updateRes.data.address1,

          city: updateRes.data.city,

          state: updateRes.data.state,

          zipcode: updateRes.data.zipcode

        })

      }

    })
    // this.editprofileForm.patchValue({
    //   fullname: this.userinfo.name,
    //   email:  this.userinfo.email,
    //   phonenumber: this.userinfo.mobileNo,
    //   streetaddress: this.userinfo.address1,
    //   city: this.userinfo.city,
    //   state: this.userinfo.state,
    //   zipcode: this.userinfo.zip
    // })
  }
  get editprofData() {
    return this.editprofileForm.controls;
  }

 
  onSubmit() {
    this.submitted = true;
    if (this.editprofileForm.invalid) {
      return;
    } else {
      const data = {
        "name": this.editprofileForm.controls['fullname'].value,
        "address1": this.editprofileForm.controls['streetaddress'].value,
        "city":this.editprofileForm.controls['city'].value,
        "state": this.editprofileForm.controls['state'].value,
        "zipcode":this.editprofileForm.controls['zipcode'].value,
        "email": this.editprofileForm.controls['email'].value
      }
      
      this.apicaller.edituser(data, this.userinfo.id).subscribe((updateRes: any) => {
        if (updateRes?.status === 'SUCCESS') {
          this.router.navigate(["/LayoutComponent/userprofile"]);
        }
      })
    }
  }

}
