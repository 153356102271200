import { Component, OnInit } from '@angular/core';
import { ApicallerService } from '../services/apicaller.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-emailverify',
  templateUrl: './emailverify.component.html',
  styleUrls: ['./emailverify.component.css']
})
export class EmailverifyComponent implements OnInit {
  token: string = '';
  constructor(private apiCaller: ApicallerService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params && params['token']) {
        this.token = params['token'];
        this.verifyEmail();
      }
    })
  }

  verifyEmail() {
    this.apiCaller.verifyEmail(this.token).subscribe((verifyRes: any) => {
      if (verifyRes && verifyRes?.message == 'verified') {
        Swal.fire({
          title: 'Success',
          text: 'Email Verified Successfully!',
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(res => {
          if (res.isConfirmed) {
            this.router.navigate(['/Login'])
          }
        })
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Unable to Process the Request',
          icon: 'error',
          confirmButtonText: 'ok'
        }).then(res => {
          if (res.isConfirmed) {
            this.router.navigate(['/welcome'])
          }
        })
      }
    })
  }

}
