import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApicallerService } from '../services/apicaller.service';
import { ConfirmedValidator } from '../customdatevalidations';

@Component({
  selector: 'app-userreport',
  templateUrl: './userreport.component.html',
  styleUrls: ['./userreport.component.css']
})
export class UserreportComponent implements OnInit {
  userinfo : any = localStorage.getItem('userInfo');
  maxDate = new Date();
  opts: any;
  filterType: string = '';
  isCustom: boolean = false;
  constructor(public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public apicaller: ApicallerService) { 
      
    }
  customDate:any = false;
  transactioForm :any = UntypedFormGroup;
  submitted = false;
  selectedRadio = '';
  mintoDate: any;
  startDate: string = '';
  endDate: string = '';
  ngOnInit(): void {
    this.transactioForm = this.formBuilder.group({
      startdate: ['', [Validators.required]],
      enddate: ['', [Validators.required]]
    });    
    if (this.userinfo) {
      this.userinfo = JSON.parse(this.userinfo);
    }
    this.navigateToTransactions('today');
    // this.transactioForm = new FormGroup(
    //   {
    //     startdate: new FormControl(""),
    //     enddate: new FormControl("")
    //   },
    //   [Validators.required, this.dateRangeValidator],
      
    
    // );
  }

  get CalenderData() {
    return this.transactioForm.controls;
  }

  userprofile(){
    this.router.navigate(["/userprofile"]);
  }
  logout(){
    this.router.navigate(['/welcome']);
    localStorage.clear();
  }
  EndDateChange(event: any) {
    this.mintoDate = this.transactioForm.value.FromDate;
    console.log(this.mintoDate);
   }

   navigateToTransactions(reqType: string) {
    this.filterType = reqType;
    const currentMs = new Date().getTime();
    const requestObj = { startdate: new Date(new Date().getTime() - 86400000).toISOString(), enddate: new Date(currentMs).toISOString(), opts: "asc" };
    switch(reqType) {
      case 'yesterday': {
        this.startDate = new Date(new Date().setHours(-25, 0, 0, 0)).toISOString();
        this.endDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
        break;
      }
      case 'today': {
        this.startDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
        this.endDate = new Date(new Date().setHours(24, 0, 0, 0)).toISOString();
        break;
      }
      case 'custom': {
        this.startDate = new Date(new Date(this.transactioForm.controls['startdate'].value).getTime()).toISOString();
        this.endDate = new Date(new Date(this.transactioForm.controls['enddate'].value).setHours(18, 0, 0, 0)).toISOString();
        break;  
      }                         
    }
    // this.router.navigate(['/LayoutComponent/usertable'], { queryParams: requestObj });
   }

  onSubmit() {
    this.submitted = true; 
    if (this.transactioForm.invalid) {
      return;
    } else {
      this.isCustom = true
      console.log(this.transactioForm.value);
      this.navigateToTransactions('custom');
    }
  }
  custom(){
    this.customDate = true;
  }

  // private dateRangeValidator: ValidatorFn = (): {
  //   [key: string]: any;
  // } | null => {
  //   let invalid = false;
  //   const from = this.transactioForm.value.startdate;
  //   const to = this.transactioForm.value.enddate;
  //   if (from && to) {
  //     invalid = new Date(from).valueOf() > new Date(to).valueOf();
  //   }
  //   return invalid ? { invalidRange: { from, to } } : null;
  // };
}

