<div class="container-fluid merchantLogin">
  <div class="row">
    <a
      class="welcome_logo mt-4 ms-3"
      href="#"
      routerLink="/welcome"
      title="Oblingo"
      ><img src="../../assets/images/logo2.png" alt=""
    /></a>
  </div>
  <div class="row loginOrder">
    <div
      class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 hidedata text-center"
    >
      <img class="imgleftlogo" src="../../assets/images/storeimg.png" />
    </div>
    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 loginnumber">
      <div class="well">
        <div class="card-body">
          <form [formGroup]="merchantloginForm" (ngSubmit)="onSubmit()" class="my-form">
            <div class="d-flex flex-column">
              <input
                type="text"
                placeholder="Email ID"
                class="form-control my-2 p-4"
                formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && tempData.email.errors }"
              />
              <div
                *ngIf="submitted && tempData.email.errors"
                class="invalid-feedback"
              >
                <div *ngIf="tempData.email.errors.required">
                  Email is required
                </div>
                <div *ngIf="tempData.email.errors.pattern">
                  Email must be a valid email address
                </div>
              </div>
            </div>

            <div class="d-flex position-relative flex-column">
              <input
                [type]="fieldTextType ? 'text' : 'password'"
                placeholder="Password"
                class="form-control my-2 p-4"
                formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && tempData.password.errors }"
              />
              <div class="input-group-append pass_visible">
                <span class="input-group-text">
                  <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType }" (click)="toggleFieldTextType()"></i>
                </span>
             </div>
              
              <div
                *ngIf="submitted && tempData.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="tempData.password.errors.required">
                  Password is required
                </div>
              </div>
              <a class="forgotpass" routerLink="/ForgotPassword">Forgot Password?</a>
            </div>

            <div></div>
            <button type="submit" class="btn1">Login</button>

            <div class="help">
              <p class="forgottext">
                Don't Have An Account Yet?
                <br />
                <a routerLink="/Register">Register</a> Now
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
