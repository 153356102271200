import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';  
import { UsertypeComponent } from './usertype/usertype.component';
import { UserloginComponent } from './login/userlogin/userlogin.component';
import { RegisterComponent } from './register/register.component'; 
import { MerchantLoadingComponent } from './Merchant/merchant-loading/merchant-loading.component';
import { ProfileComponent } from './profile/profile.component'; 
import { ChangepasswordComponent} from './changepassword/changepassword.component';
import { UserprofileComponent} from './userprofile/userprofile.component';
import { EditprofileComponent} from './editprofile/editprofile.component';
import { RouteauthenticatorGuard } from './routeauthentication/routeauthenticator.guard';
import { LayoutComponent } from './layout/layout.component';
import { ForgotpasswordComponent } from './Merchant/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './Merchant/resetpassword/resetpassword.component';
import { EmailverifyComponent } from './emailverify/emailverify.component';
import { PaymentactivityComponent } from './paymentactivity/paymentactivity.component';
import { PaymenttableComponent } from './paymenttable/paymenttable.component';

const routes: Routes = [
  {path : '',  redirectTo: '/welcome', pathMatch: 'full'},
  { path: 'Login', component: LoginComponent },
  { path: 'ForgotPassword', component: ForgotpasswordComponent },
  { path: 'ResetPassword', component: ResetpasswordComponent },
  { path: 'UserLogin',  component: UserloginComponent,
  data: {
    role: 'user'
  } },
  { path: 'Register', component: RegisterComponent,
  data: {
    role: 'merchant'
  }},
  { path: 'UserProfile', component: UserprofileComponent },
  { path: 'ProfileDetails', component: ProfileComponent, canActivate: [RouteauthenticatorGuard] },
  
  { path: 'welcome', component: UsertypeComponent },
 
  { path: 'changepassword', component:ChangepasswordComponent,canActivate: [RouteauthenticatorGuard]},
  // { path: 'report', component:UserreportComponent},
  // { path: 'userprofile', component:UserprofileComponent, canActivate: [RouteauthenticatorGuard],
  // data: {
  //   role: 'user'
  // }},
  // { path: 'editprofile', component:EditprofileComponent,canActivate: [RouteauthenticatorGuard]},
  { path: 'MerchantComponent', component:MerchantLoadingComponent, 
  canActivate: [RouteauthenticatorGuard],
        data: {
          role: 'merchant'
        },
  children:[{
    path:'',
  loadChildren: () => import('./Merchant/merchant-loading/merchant-loading.module').then(m => m.MerchantLoadingModule),
  
  }]
  },
  { path: 'LayoutComponent', component:LayoutComponent, 
  canActivate: [RouteauthenticatorGuard],
        data: {
          role: 'user'
        },
  children:[{
    path:'',
  loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
  
  }]
  },
  {path: 'Merchant/emailverify', component:EmailverifyComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
