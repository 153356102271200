import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
// import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class LoaderServiceService {

  constructor(public Loader:NgxSpinnerService, private toastService: ToastrService) { }

  present(){
    this.Loader.show(); 
  }
  close(){
    this.Loader.hide(); 
  }

  successNotification(msg:any) {
    // Swal.fire({
    //   title: '',
    //   text: msg,
    //   icon: 'success',
    //   confirmButtonText: 'Next',
    //   customClass: {
    //     container: "swalbg"
    //   }
    // });
    this.toastService.success(msg);
  }
  failNotification(msg:any) {
    // Swal.fire('Alert', msg, 'error');
    this.toastService.error(msg);
  }
}
