import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApicallerService } from '../services/apicaller.service';

@Injectable({
  providedIn: 'root'
})
export class RouteauthenticatorGuard implements CanActivate {
  userlogin:any;
    
    
  constructor(private apicallerservice: ApicallerService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.userlogin= localStorage.getItem("ROLE");
    if(this.userlogin==''||this.userlogin==null||this.userlogin==undefined){
      this.router.navigate(['/welcome'], { queryParams: { returnUrl: state.url }});
      return false;
    }else{           
      return true;
    }    
      
  }
  // canActivateChild(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return this.canActivate(next, state);
  // }
  // canDeactivate(
  //   component: unknown,
  //   currentRoute: ActivatedRouteSnapshot,
  //   currentState: RouterStateSnapshot,
  //   nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
  //   return true;
  // }

  // checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    // if (this.apicallerservice.isLoggedIn()) {
    //   const userRole = this.apicallerservice.getRole();
    //   if (route.data['role'] && route.data['role'].indexOf(userRole) === -1) {
    //     this.router.navigate(['/welcome']);
    //     return false;
    //   }
    //   return true;
    // }

    // this.router.navigate(['/welcome']);
    // return false;
  // }
}
