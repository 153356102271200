import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmedValidator } from '../confirmpasswordval';
import { ApicallerService } from 'src/app/services/apicaller.service';
import { LoaderServiceService } from '../services/loader-service.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  changepasswordForm: any = UntypedFormGroup;
  submittedcpwd: boolean = false;
  submitted = false;
  fieldTextType: boolean = false;
  fieldTextType1: boolean = false;
  constructor( public router: Router,
    private formBuilder: UntypedFormBuilder,
    public apicaller: ApicallerService,
    public loaderservice : LoaderServiceService) { }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }
  ngOnInit(): void {
    this.changepasswordForm = this.formBuilder.group(
      {
        currentpassword: ['', [Validators.required]],
        password: ['', [Validators.required]],
        newpassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmedValidator('password', 'newpassword'),
      }
    );
  }
  get changepasswordData() {
    return this.changepasswordForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    this.submittedcpwd = true;
    if (this.changepasswordForm.invalid) {
      return;
    } else {
      this.apicaller.changePassword({ oldPassword: this.changepasswordForm.controls['currentpassword'].value, newPassword: this.changepasswordForm.controls['newpassword'].value })
      .subscribe((response: any) => {
        if (response?.status === 'SUCCESS') {
          this.loaderservice.successNotification("Password Changed Successfully.");
          this.router.navigate(['/Login'], { queryParams: { role: 'merchant'}})
        } else { 
          this.loaderservice.failNotification(response.message);
        }
      })
    }
  }
}
