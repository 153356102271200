import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApicallerService } from '../services/apicaller.service';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css']
})
export class UserprofileComponent implements OnInit {
  userinfo : any = localStorage.getItem('userInfo');
  userData: any = {};
  constructor(public router: Router, private apiCaller: ApicallerService) { }

  ngOnInit(): void {
    if (this.userinfo) {
      this.userinfo = JSON.parse(this.userinfo);
      this.getUser();
    }
  }
  changepwd(){
    this.router.navigate(["/changepassword"]);

  }
  edit(){
    this.router.navigate(["/ProfileDetails"], { queryParams: { user: this.userinfo['id'] } });
  }
  getUser() {
    this.apiCaller.getUser({}, this.userinfo.id).subscribe((userRes: any) => {
      if (userRes?.status == 'SUCCESS') {
        this.userData = userRes?.data;
      }
    })
  }
  
  

}
