<div class="container-fluid">
  <div class="row">
    <a
      class="welcome_logo mt-4 ms-3"
      href="#"
      routerLink="/welcome"
      title="Oblingo"
      ><img src="../../assets/images/logo2.png" alt=""
    /></a>
  </div>
  <div class="row loginOrder">
    <div
      class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 hidedata text-center"
    >
      <img class="imgleftlogo" src="../../assets/images/userbg.png" />
    </div>
    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 loginnumber">
      <div class="well">
        <div class="card-body">
          <form
            [formGroup]="profileForm"
            (ngSubmit)="onSubmit()"
            class="my-form"
          >
            <div></div>
            <label class="pageTitle">Profile Details</label>
            <div class="d-flex flex-column">
              <input
                type="text"
                placeholder="Full Name"
                class="form-control my-2 p-4"
                formControlName="fullname"
                [ngClass]="{ 'is-invalid': submitted && profData.fullname.errors }"
                
              />
              <div
                *ngIf="submitted && profData.fullname.errors"
                class="invalid-feedback"
              >
                <div *ngIf="profData.fullname.errors.required">
                  Full Name is required
                </div>
              </div>
            </div>
            <div class="d-flex flex-column">
              <input
                type="text"
                placeholder="Email Id"
                class="form-control my-2 p-4"
                formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && profData.email.errors }"
              />
              <div
                *ngIf="submitted && profData.email.errors"
                class="invalid-feedback"
              >
                <div *ngIf="profData.email.errors.required">
                  Email is required
                </div>
                <div *ngIf="profData.email.errors.email">
                  Email must be a valid email address
                </div>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between streetcity">
              <div
                class="d-flex justify-content-start flex-fill me-3 flex-column"
              >
                <input
                  type="text"
                  placeholder="Street Address"
                  class="form-control common my-2 p-4"
                  formControlName="streetaddress"
                  [ngClass]="{ 'is-invalid': submitted && profData.streetaddress.errors }"
                />
                <div
                  *ngIf="submitted && profData.streetaddress.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="profData.streetaddress.errors.required">
                    Street Address is required
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end city flex-column">
                <input
                  type="text"
                  placeholder="City"
                  class="form-control my-2 p-4"
                  formControlName="city"
                  [ngClass]="{ 'is-invalid': submitted && profData.city.errors }"
                />
                <div
                  *ngIf="submitted && profData.city.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="profData.city.errors.required">
                    City is required
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <div
                class="d-flex justify-content-start flex-fill me-3 bg_select"
              >
                <select
                  placeholder="State/ Province"
                  class="form-control opt common my-2 p-2"
                  formControlName="state"
                  [ngClass]="{ 'is-invalid': submitted && profData.state.errors }"
                >
                  <option value="" disabled default>Select your State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
                </select>
                <div
                  *ngIf="submitted && profData.state.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="profData.state.errors.required">Select State</div>
                </div>
              </div>
              <div
                class="d-flex justify-content-end flex-fill flex-column city"
              >
                <input
                  type="text"
                  placeholder="Zip Code"
                  class="form-control my-2 p-4"
                  formControlName="zipcode"
                  [ngClass]="{ 'is-invalid': submitted && profData.zipcode.errors }"
                />
                <div
                  *ngIf="submitted && profData.zipcode.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="profData.zipcode.errors.required">
                    Zipcode is required
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex" *ngIf="!userId">
              <a class="payDetails" id="payButton" href="payment details">Add Payment Details</a>
            </div>
            <div class="existing_pay_details" *ngIf="userId">
              <label class="pageTitle">Payment Details</label>
              <div class="d-flex align-items-center">
                <span class="card_label">Credit Card Number :</span> <label class="cardNo">{{userData?.cardDetails?.cardNo}}</label>
                <a class="card_edit_icn" id="payButton" href="payment details"><img src="../../assets/images/card_edit.png" alt="" /></a>
              </div>
            </div>
            <button
              type="submit"
              [disabled]="!profileForm.valid"
              class="btn1"
              (click)="onPayment()"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
