import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogocontainerModule } from '../shared/logocontainer/logocontainer.module';
import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { UsertableComponent } from '../usertable/usertable.component';
import { UserreportComponent } from '../userreport/userreport.component';
import { PaymentStatementComponent } from '../payment-statement/payment-statement.component';
import { UserprofileComponent } from '../userprofile/userprofile.component';
import { EditprofileComponent } from '../editprofile/editprofile.component';
import { ChangepasswordComponent } from '../changepassword/changepassword.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DataTablesModule } from 'angular-datatables';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentactivityComponent } from '../paymentactivity/paymentactivity.component';
import { PaymenttableComponent } from '../paymenttable/paymenttable.component';
import { NgxSpinnerModule} from 'ngx-spinner'

@NgModule({
  declarations: [
    LayoutComponent,
    UsertableComponent,
    UserreportComponent,
    PaymentStatementComponent,
    ChangepasswordComponent,
    UserprofileComponent,
    EditprofileComponent,
    PaymentactivityComponent,
    PaymenttableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutRoutingModule,
    LogocontainerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatAutocompleteModule,
    DataTablesModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' })
  ],
   schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutModule { }
