<div class="container-fluid editmerc">
  <div class="user_prof_details">
    <h4>Profile Details</h4>
    <form [formGroup]="editprofileForm" (ngSubmit)="onSubmit()" class="my-form">
      <div class="col-12 d-flex justify-content-center align-items-center mb-2">
        <div class="col-3 d-flex flex-row justify-content-between Qlabel">
          <label>Name</label><span>:</span>
        </div>
        <div class="col-9">
          <div class="d-flex flex-column ms-5">
            <input
              type="text"
              placeholder="Full Name"
              class="form-control my-2 p-4"
              formControlName="fullname"
              [ngClass]="{ 'is-invalid': submitted && editprofData.email.errors }"
            />
            <div
              *ngIf="submitted && editprofData.fullname.errors"
              class="invalid-feedback"
            >
              <div *ngIf="editprofData.fullname.errors.required">
                Full Name is required
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center align-items-center mb-2">
        <div class="col-3 d-flex flex-row justify-content-between Qlabel">
          <label>Email Address</label><span>:</span>
        </div>
        <div class="col-9">
          <div class="d-flex flex-column ms-5">
            <input
              type="text"
              placeholder="Email Id"
              class="form-control my-2 p-4"
              formControlName="email"
              [ngClass]="{ 'is-invalid': submitted && editprofData.email.errors }"
            />
            <div
              *ngIf="submitted && editprofData.email.errors"
              class="invalid-feedback"
            >
              <div *ngIf="editprofData.email.errors.required">
                Email is required
              </div>
              <div *ngIf="editprofData.email.errors.email">
                Email must be a valid email address
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center align-items-center mb-2">
        <div class="col-3 d-flex flex-row justify-content-between Qlabel">
          <label>Phone Number</label><span>:</span>
        </div>
        <div class="col-9">
          <div class="d-flex flex-column ms-5">
            <input
              type="text"
              placeholder="Enter Mobile Number"
              (keypress)="Validate($event)"
              maxlength="15"
              class="form-control my-2 p-4"
              formControlName="phonenumber"
              [ngClass]="{ 'is-invalid': submitted && editprofData.phonenumber.errors }"
              [readonly]="true" />
            <div
              *ngIf="submitted && editprofData.phonenumber.errors"
              class="invalid-feedback"
            >
              <div *ngIf="editprofData.phonenumber.errors.required">
                Mobile number is required
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center align-items-start mb-3">
        <div class="col-3 d-flex flex-row justify-content-between Qlabel">
          <label>Address</label><span>:</span>
        </div>
        <div class="col-9">
          <div class="d-flex flex-row justify-content-between streetcity ms-5">
            <div
              class="d-flex justify-content-start flex-fill me-3 flex-column"
            >
              <input
                type="text"
                placeholder="Street Address"
                class="form-control common my-2 p-4"
                formControlName="streetaddress"
                [ngClass]="{ 'is-invalid': submitted && editprofData.streetaddress.errors }"
              />
              <div
                *ngIf="submitted && editprofData.streetaddress.errors"
                class="invalid-feedback"
              >
                <div *ngIf="editprofData.streetaddress.errors.required">
                  Street Address is required
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end city flex-column">
              <input
                type="text"
                placeholder="City"
                class="form-control my-2 p-4"
                formControlName="city"
                [ngClass]="{ 'is-invalid': submitted && editprofData.city.errors }"
              />
              <div
                *ngIf="submitted && editprofData.city.errors"
                class="invalid-feedback"
              >
                <div *ngIf="editprofData.city.errors.required">
                  City is required
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between ms-5">
            <div
              class="d-flex justify-content-start flex-fill flex-column me-3 mt-2 bg_select"
            >
              <select
                placeholder="State/ Province"
                class="form-control common my-2 p-2"
                formControlName="state"
              >
                <option value="" selected>Select your State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
              <div
                *ngIf="submitted && editprofData.state.errors"
                class="invalid-feedback"
              >
                <div *ngIf="editprofData.city.state.required">Select State</div>
              </div>
            </div>
            <div class="d-flex justify-content-end flex-column mt-2 city">
              <input
                type="text"
                placeholder="Zip Code"
                class="form-control my-2 p-4"
                formControlName="zipcode"
                [ngClass]="{ 'is-invalid': submitted && editprofData.zipcode.errors }"
              />
              <div
                *ngIf="submitted && editprofData.zipcode.errors"
                class="invalid-feedback"
              >
                <div *ngIf="editprofData.zipcode.errors.required">
                  Zipcode is required
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12 d-flex justify-content-center align-items-center">
        <div class="col-3 d-flex flex-row justify-content-between Qlabel">
          <label>Profile Picture</label><span>:</span>
        </div>
        <div class="col-9">
          <div class="d-flex flex-row justify-content-between streetcity ms-5">
            <button type="button" class="uploadbtn" (click)="fileInput.click()">
              <span class="d-flex align-items-center"
                ><img class="upload_icn" src="../../assets/images/upload_icn.png" />
                Choose a file</span
              >
              <input #fileInput type="file" style="display:none;" />
            </button>
          </div>
        </div>
      </div> -->
      <div class="col-12 d-flex justify-content-center align-items-center">
        <div class="col-3 d-flex flex-row justify-content-between Qlabel"></div>
        <div class="col-9">
          <div class="d-flex flex-row justify-content-between streetcity ms-5">
        <button type="submit" class="btn1 me-3">Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
