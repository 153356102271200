import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApicallerService } from '../../services/apicaller.service';
import { LoaderServiceService } from '../../services/loader-service.service';
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  forgotPasswordForm: any = UntypedFormGroup;
  submitted = false;
  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    public apicaller: ApicallerService,
    public loaderservice : LoaderServiceService
  ) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get tempData() {
    return this.forgotPasswordForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    } else {
      this.forgotPassword();
    }
  }

  forgotPassword() {
    this.apicaller
      .merchantForgotPassword(this.forgotPasswordForm.value.email)
      .subscribe((res: any) => { 
        if (res.status === 'SUCCESS') {
          this.loaderservice.successNotification("Reset code sent your Email.");
          setTimeout(() => {
            this.router.navigate(['/ResetPassword']);
          }, 2000)
        } else {
          this.loaderservice.failNotification(res.message);
        }
      });
  }

}
