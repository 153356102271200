import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, Observable, of, startWith } from 'rxjs';
import { ApicallerService } from '../services/apicaller.service';

@Component({
  selector: 'app-payment-statement',
  templateUrl: './payment-statement.component.html',
  styleUrls: ['./payment-statement.component.css']
})
export class PaymentStatementComponent implements OnInit {
  userinfo : any = localStorage.getItem('userInfo');
  merchantId: any;
  merchants: Array<any> = [];
  constructor(public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public apicaller: ApicallerService) { 
  }
  showResults:any = false;
  transactioForm:any = FormGroup;
  submitted = false;
  selectedRadio = '';
  mintoDate: any;
  maxDate = new Date();
  isData: boolean = false;
  startDate: string = '';
  endDate: string = '';
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any> = new Observable<any>();
  ngOnInit(): void {
    this.getMerchants();
    this.transactioForm = this.formBuilder.group({
      startdate: [''],
      enddate: [''],
      merchantId: ['']
    });
    this.filteredOptions = this.transactioForm.get('merchantId')!.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || '')),
    );
    if (this.userinfo) {
      this.userinfo = JSON.parse(this.userinfo);
    }
  }

  get CalenderData() {
    return this.transactioForm.controls;
  }

  userprofile(){
    this.router.navigate(["/userprofile"]);
  }
  logout(){
    this.router.navigate(['/welcome']);
    localStorage.clear();
  }
  filterback(){
    this.router.navigate(['LayoutComponent/payment-activity']);
  }
  EndDateChange(event: any) {
    this.mintoDate = this.transactioForm.value.FromDate;
    console.log(this.mintoDate);
   }

   navigateToTransactions(reqType: string) {
    const currentMs = new Date().getTime();
    const requestObj = { startdate: new Date(new Date().getTime() - 86400000).toISOString(), enddate: new Date(currentMs).toISOString(), opts: "asc" };
    switch(reqType) {
      case 'customs': {
        requestObj['startdate'] = new Date(new Date(this.transactioForm.controls['startdate'].value).getTime()).toISOString();
        requestObj['enddate'] = new Date(new Date(this.transactioForm.controls['enddate'].value).setHours(7, 0, 0, 0)).toISOString();
        requestObj['opts'] = this.transactioForm.controls['options'].value;
        break;
      }                         
    }
    // this.router.navigate(['/LayoutComponent/payment-activity'], { queryParams: requestObj });
   }

  onSubmit() {
    this.submitted = true;
    this.isData = false; 
    if (this.transactioForm.invalid) {
      return;
    } else {
      if (this.transactioForm.controls['startdate'].value) {
        this.startDate = new Date(new Date(this.transactioForm.controls['startdate'].value).getTime()).toISOString();
      }
      if (this.transactioForm.controls['enddate'].value) {
        this.endDate = new Date(new Date(this.transactioForm.controls['enddate'].value).setHours(7, 0, 0, 0)).toISOString();
      }
      if (this.transactioForm.controls['merchantId'].value) {
        this.merchantId = this.merchants.find(m => m.email == this.transactioForm.controls['merchantId'].value).merchantUserId;
      } else {
        this.merchantId = '';
      }
      this.isData = true;
    }
  }

  getMerchants() {
    this.apicaller.getMerchants().subscribe((response: any) => {
      if (response?.status === 'SUCCESS') {
        this.merchants = response?.data?.data || [];
      }
    })
   }

   getMerchantData() {
    return of(this.merchants);
   }

   private _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.merchants.filter(m => m.email.toLowerCase().includes(filterValue))
  }

}
