import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-paymentactivity',
  templateUrl: './paymentactivity.component.html',
  styleUrls: ['./paymentactivity.component.css']
})
export class PaymentactivityComponent implements OnInit {
  userinfo : any = localStorage.getItem('userInfo');
  sortType: string = 'month';
  startDate: string = '';
  endDate: string = '';
  showResults:any = false;
  transactioForm:any = FormGroup;
  submitted = false;
  selectedRadio = '';
  mintoDate: any;
  isData: boolean = false;
  

  constructor(public router: Router) { }

  ngOnInit(): void {
    this.getDates('month');
  }

  filtersearch(){
    this.router.navigate(['LayoutComponent/paymentstatements']);
  }

  getDates(type: string) {
    const date = new Date();
    switch(type) {
      case 'month': this.startDate = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
                    this.endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
                    break;
      case 'year':  this.startDate = new Date(date.getFullYear(), 0, 1).toISOString();
                    this.endDate = new Date(date.getFullYear(), 11, 31).toISOString();
                    break;
    }
  }

      
}
 
