
<div class="container-fluid editmerc" *ngIf="filterType == 'today' || filterType == 'yesterday' || (filterType == 'custom' && isCustom)">
  <div class="user_reporttable">
    <!-- <label class="reportlab">Transaction Activity</label> -->
    <div class="table-responsive mtop reports_table main_table" *ngIf="isReport">
      <table class="table table-striped table-bordered" id="myTable">
        <thead>
          <tr class="info">
            <th>Transaction Date</th>
            <th>Order Id</th>
            <th>Purchased Products</th>
            <th>Debit Amount($)</th>
            <th>Quantity</th>
            <th>Expense ($)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let post of Data">
            <td>{{post?.createdAt | date: 'MM/dd/yyyy' }}</td>
            <td>{{post.orderid}}</td>
            <td>{{post?.products[0]?.name}}</td>
            <td>{{convertToFloat(post?.purchse_value)}}</td>
            <td>{{post?.products[0]?.quantify}}</td>
            <td>{{convertToFloat(post?.availableBalanceCredit)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bottombtn d-flex justify-content-between">
      <p>
      </p>
      <button class="btn2" (click)="download()">
        <img src="../../../assets/images/download.png" />Download Transaction Report</button>
    </div>
  </div>
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
</div>
