import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ApicallerService } from '../services/apicaller.service';
import { LoaderServiceService } from 'src/app/services/loader-service.service';
declare var $: any;
@Component({
  selector: 'app-paymenttable',
  templateUrl: './paymenttable.component.html',
  styleUrls: ['./paymenttable.component.css']
})
export class PaymenttableComponent implements OnInit {
  selectedstatus: string="0";
  userinfo : any = localStorage.getItem('userInfo');
  Data:any;
  filename: any;
  
  requestObj: { startdate?: string, enddate?: string, merchantId?: string, startDate?: string } = {};
  @Input() sortType: string = '';
  @Input() startDate: string = '';
  @Input() endDate: string = '';
  @Input() merchantId: string = '';
  merchants: Array<any> = [];
  constructor(public router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderServiceService,
    private apiCallerService: ApicallerService) { }

  ngOnInit(): void {
    this.loaderService.present()
    $('#myTable').dataTable().fnDestroy();
    setTimeout(() => {
      this.loaderService.close()
     },2000)
    this.requestObj['startdate'] = this.startDate;
    this.requestObj['enddate'] = this.endDate;
    if (this.merchantId) {
      this.requestObj['merchantId'] = this.merchantId;
    } else {
      delete this.requestObj['merchantId'];
    }
    if (this.userinfo) {
      this.userinfo = JSON.parse(this.userinfo);
    }
    this.getTransactions();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const change in changes) {
      if (change == 'startDate') {
        this.requestObj['startdate'] = changes[change].currentValue;
      }
      if (change == 'endDate') {
        this.requestObj['enddate'] = changes[change].currentValue;
      }
      if (change == 'merchantId') {
        this.merchantId = changes[change].currentValue;
      }
    }
    this.getTransactions();
    // Object.keys(changes).forEach((change:any) => {
    //   if (change === 'startDate') {
    //     this.requestObj['startdate'] = changes[change].currentValue;
    //   }
    //   if (change === 'endDate') {
    //     this.requestObj['enddate'] = changes[change].currentValue;
    //   }
    //   if (change === 'merchantId') {
    //     this.merchantId = changes[change].currentValue;
    //   }
    // });
    // this.getTransactions();
  }
  download(){    

    if(this.Data.length!=0){   
      this.filename='Transaction Report' ; 
      this.downloadFile(this.Data, this.filename);
      }else{
        alert("No data available in table.");
      }
  }
  userprofile(){
    this.router.navigate(["/userprofile"]);
  }
  logout(){
    this.router.navigate(['/welcome']);
    localStorage.clear();
  }
  userreport(){
    this.router.navigate(['LayoutComponent/report']);
  }

  viewdetails(merchantId: string, date: string){
    let merchants = this.Data.find((d: any) => d?.merchant_id?.id == merchantId && d?.date == date);
    const queryParams: any = {};
    if (merchants) {
      merchants = merchants?.products;
      merchants = JSON.stringify(merchants); 
    }
    queryParams.values = merchants;
    const navigationExtras: NavigationExtras = {
      queryParams
    }
    this.router.navigate(['LayoutComponent/paymentdetails'], navigationExtras);
  }
 
  downloadFile(data:any, filename='data') { 
    
    let date =filename;        
    let csvData = this.ConvertToCSV(data, ['Transaction Date','Order Id','Product Name', 'Debit Amount($)', 'Quantity', 'Balance($)']);
    let blob;
    if(date==''){
       blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
   
    }else{
      blob = new Blob(['\ufeff' +  date + '\r\n' + csvData], { type: 'text/csv;charset=utf-8;' });
   
    } 
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if(isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray:any, headerList:any) {
     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
     let str = '';       
     let row = 'S.No,';  
     for (let index in headerList) {
         row += headerList[index] + ',';
     }
     row = row.slice(0, -1);
      str += row + '\r\n';
     for (let i = 0; i < array.length; i++) {       
         let line = (i+1)+'';            
         for (let index in headerList) { 
            let head = headerList[index];
             line += ',' + array[i][head];              
         }
         str += line + '\r\n';
     }
     return str;
     
 }

 getTransactions() {debugger
  $('#myTable').dataTable().fnDestroy();
  this.loaderService.present()
  if(this.sortType === 'month'){
    if (this.requestObj) {
      // if (!this.requestObj['startdate']) {
      //   delete this.requestObj['startdate'];
      // }
      // if (!this.requestObj['enddate']) {
      //   delete this.requestObj['enddate'];
      // }
      // if (!this.requestObj['merchantId']) {
      //   delete this.requestObj['merchantId'];
      // }
      console.log('==== request =====', this.requestObj);
      console.log(this.sortType);
      this.apiCallerService.getPaymentTransactions(this.requestObj).subscribe((response: any) => {
        
        if (response?.status === 'SUCCESS') {
           this.Data = response?.data || [];
           this.Data = this.filterTransactions();
          if (this.merchantId) {
            this.Data = this.Data.filter((d: any) => d?.merchant_id?.id == this.merchantId);
          }
          var self = this;
          setTimeout(() => {
            setTimeout(() => {
              var table = $('#myTable').DataTable(
                {
                  "pageLength": 10,
                  "searching": false,
                  "lengthChange": false,
                  "bDestroy": true,
                 
                },
                1500
              );
            });
            this.loaderService.close()
          }, 1000)
        }
      })
    }
  } else {
    if (this.requestObj) {
      // if (!this.requestObj['startdate']) {
      //   delete this.requestObj['startdate'];
      // }
      // if (!this.requestObj['enddate']) {
      //   delete this.requestObj['enddate'];
      // }
      // if (!this.requestObj['merchantId']) {
      //   delete this.requestObj['merchantId'];
      // }
      console.log('==== request =====', this.requestObj);
      console.log(this.sortType);
      this.apiCallerService.getPaymentTransactions(this.requestObj).subscribe((response: any) => {
        
        if (response?.status === 'SUCCESS') {
           this.Data = response?.data || [];
           this.Data = this.filterTransactions();
          if (this.merchantId) {
            this.Data = this.Data.filter((d: any) => d?.merchant_id?.id == this.merchantId);
          }
          var self = this;
          setTimeout(() => {
            setTimeout(() => {
              var table = $('#myTable').DataTable(
                {
                  "pageLength": 10,
                  "searching": false,
                  "lengthChange": false,
        
                  "bDestroy": true,
                 
                },
                1500
              );
            });
            this.loaderService.close()
          }, 1000)
        }
      })
    }
  }
 }
 convertToFloat(val: string): string {
  return parseFloat(val || '0').toFixed(2);
 }
 getBalance(purchase: string, credit: string) {
  return (parseFloat(purchase || '0') - parseFloat(credit || '0'));
 }

 filterTransactions() {
   const transactions = this.Data.map((d:any) => ({ date: d.date_of_payment, transactions: d.transactions }));
   let retValues: Array<any> = [];
   for (const transaction of transactions) {
    const index = retValues.findIndex((val: any) => val.date == transaction.date);
    if (index !== -1) {
      transaction.transactions = transaction.transactions.map((trans: any) => ({ ...trans, date: transaction.date }));
      retValues[index].transactions.push(...transaction.transactions);
    } else {
      if (transaction?.transactions?.length) {
        retValues.push({ date: transaction.date, transactions: transaction.transactions.map((trans: any) => ({ ...trans, date: transaction.date })) });
      }
    }
   }
   retValues = retValues.map(ret => ret.transactions).flat(1);
   const response: Array<any> = [];
   retValues.forEach(retVal => { 
    const index = response.findIndex((res: any) => res?.date == retVal?.date && res?.merchant_id?.id == retVal?.merchant_id?.id);
    if (index !== -1) {
      response[index].purchse_value = parseFloat(response[index]?.purchse_value) + parseFloat(retVal?.purchse_value);
      response[index].products.push(...retVal?.products);
    } else {
      response.push(retVal);
    }
   });
   return response;
 }

 getMerchants() {
  this.apiCallerService.getMerchants().subscribe((response: any) => {
    if (response?.status === 'SUCCESS') {
      this.merchants = response?.data || [];
    }
  })
 }
 converttodate(datestring: string){
  const dates = datestring.split("/");
  return new Date(`${dates[2]}-${dates[1]}-${dates[0]}`).toISOString();
 }

}
