import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmedValidator } from '../confirmpasswordval';
import { ApicallerService } from 'src/app/services/apicaller.service';
import { LoaderServiceService } from '../services/loader-service.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  registerForm: any = UntypedFormGroup;
  submittedcpwd: boolean = false;
  submitted = false;
  fieldTextType: boolean = false;
  constructor(private apiCaller: ApicallerService, public router: Router, private formBuilder: UntypedFormBuilder,
    public loaderservice : LoaderServiceService) {}
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  ngOnInit(): void {
    this.registerForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        password: ['', [Validators.required]],
        newpassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmedValidator('password', 'newpassword'),
      }
    );
  }

  get tempData() {
    return this.registerForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    this.submittedcpwd = true;
    if (this.registerForm.invalid) {
      return;
    } else {
      this.register();
      console.log(this.registerForm.value);
    }
  }

  register(){ debugger
    this.apiCaller
      .registerMerchant(this.registerForm.value.password, this.registerForm.value.email)
      .subscribe((response: any) => {
        if (response.status === 'SUCCESS') {
          this.loaderservice.successNotification("You have signed up successfully! Please check your email to verify your account.");
          this.router.navigate(['/Login']);
        } else {
          this.loaderservice.failNotification(response.message);
          
        }
      });
  }
}
