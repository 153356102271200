import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule ,ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import {MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LogocontainerComponent } from './shared/logocontainer/logocontainer.component';
import { LogocontainerModule } from './shared/logocontainer/logocontainer.module';
import { LayoutModule } from './layout/layout.module';
import { UsertypeComponent } from './usertype/usertype.component';
import { UserloginComponent } from './login/userlogin/userlogin.component';
import { RegisterComponent } from './register/register.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http'; 
import { MerchantLoadingComponent } from './Merchant/merchant-loading/merchant-loading.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './profile/profile.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import { DataTablesModule } from 'angular-datatables';
import { UsertableComponent } from './usertable/usertable.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthHeaderInterceptor } from './interceptors/auth.interceptor';
import { ForgotpasswordComponent } from './Merchant/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './Merchant/resetpassword/resetpassword.component';
import { EmailverifyComponent } from './emailverify/emailverify.component';
import { PaymentdetailsComponent } from './paymentdetails/paymentdetails.component';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogocontainerComponent,
    UsertypeComponent,
    UserloginComponent,
    RegisterComponent, 
    MerchantLoadingComponent, ProfileComponent, ForgotpasswordComponent, ResetpasswordComponent, EmailverifyComponent, PaymentdetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    HttpClientModule,
    LogocontainerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    DataTablesModule,
    MatInputModule,
    MatRadioModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true
    }
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
