<div class="container-fluid">
  <div class="row topcss">
    <a
      class="welcome_logo mt-4 ms-3"
      href="#"
      routerLink="/welcome"
      title="Oblingo"
      ><img src="../../assets/images/logo2.png" alt="Oblingo"
    /></a>
    <main class="login_bg">
      <section class="userBg">
        <button type="submit" (click)="userlogin()" class="btn loginUsr">
          User
        </button>
      </section>
      <section class="merchantBg">
        <div class="cnt_bg">
          <button
            type="submit"
            (click)="merchantlogin()"
            class="btn loginMrcht"
          >
            Merchant
          </button>
        </div>
      </section>
    </main>
    <!-- <div class="col-md-5 col-lg-5 col-xl-5 col-sm-12 col-xs-12  textimgp">
      <div class="logo width-100">
        <img class="logo" src="../../assets/images/logo2.png" alt="" />
      </div>
      <div class="center imgbg1">
        <button type="submit" (click)="merchantlogin()" class="btn1">USER</button>
      </div> 
      <img class="imgleftlogo hidedata" src="../../assets/images/userbg.png" alt="" />
     
    
    </div>
    <div class="col-md-7 col-lg-7 col-xl-7 col-sm-12 col-xs-12">
      <div class="imgbg">
      <div class="center1"> 
        <button type="submit" (click)="userlogin()" class="btn2">MERCHANT</button>
      </div>
      <img class="imgleftlogo1 hidedata" src="../../assets/images/merchant.png" />
     
    </div></div> -->
  </div>
</div>
